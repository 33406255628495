import { Script } from "gatsby";
import React from "react";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {process.env.NODE_ENV === "production" && (
        <>
          <Script strategy="idle">
            {`
      (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "kj4d4ikz8z"); 
      `}
          </Script>
          <Script
            src="https://tag.clearbitscripts.com/v1/pk_79d769a965c5ccb96008d5556043b1d4/tags.js"
            referrerPolicy="strict-origin-when-cross-origin"
            strategy="idle"
          />
        </>
      )}
    </>
  );
};
