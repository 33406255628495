exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-ecommerce-tsx": () => import("./../../../src/pages/ecommerce.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-healthcare-resources-apps-tsx": () => import("./../../../src/pages/healthcare/resources/apps.tsx" /* webpackChunkName: "component---src-pages-healthcare-resources-apps-tsx" */),
  "component---src-pages-healthcare-resources-faq-tsx": () => import("./../../../src/pages/healthcare/resources/faq.tsx" /* webpackChunkName: "component---src-pages-healthcare-resources-faq-tsx" */),
  "component---src-pages-healthcare-resources-index-tsx": () => import("./../../../src/pages/healthcare/resources/index.tsx" /* webpackChunkName: "component---src-pages-healthcare-resources-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-business-app-details-tsx": () => import("./../../../src/templates/business-app-details.tsx" /* webpackChunkName: "component---src-templates-business-app-details-tsx" */),
  "component---src-templates-business-apps-tsx": () => import("./../../../src/templates/business-apps.tsx" /* webpackChunkName: "component---src-templates-business-apps-tsx" */),
  "component---src-templates-ecommerce-details-tsx": () => import("./../../../src/templates/ecommerce-details.tsx" /* webpackChunkName: "component---src-templates-ecommerce-details-tsx" */),
  "component---src-templates-ecommerce-resources-tsx": () => import("./../../../src/templates/ecommerce-resources.tsx" /* webpackChunkName: "component---src-templates-ecommerce-resources-tsx" */),
  "component---src-templates-healthcare-app-tsx": () => import("./../../../src/templates/healthcare-app.tsx" /* webpackChunkName: "component---src-templates-healthcare-app-tsx" */),
  "component---src-templates-healthcare-faq-tsx": () => import("./../../../src/templates/healthcare-faq.tsx" /* webpackChunkName: "component---src-templates-healthcare-faq-tsx" */),
  "component---src-templates-industry-details-tsx": () => import("./../../../src/templates/industry-details.tsx" /* webpackChunkName: "component---src-templates-industry-details-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/product-details.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */),
  "component---src-templates-recent-blogs-tsx": () => import("./../../../src/templates/recent-blogs.tsx" /* webpackChunkName: "component---src-templates-recent-blogs-tsx" */),
  "component---src-templates-service-details-tsx": () => import("./../../../src/templates/service-details.tsx" /* webpackChunkName: "component---src-templates-service-details-tsx" */),
  "component---src-templates-shopify-app-details-tsx": () => import("./../../../src/templates/shopify-app-details.tsx" /* webpackChunkName: "component---src-templates-shopify-app-details-tsx" */),
  "component---src-templates-shopify-apps-tsx": () => import("./../../../src/templates/shopify-apps.tsx" /* webpackChunkName: "component---src-templates-shopify-apps-tsx" */),
  "component---src-templates-shopify-category-list-tsx": () => import("./../../../src/templates/shopify-category-list.tsx" /* webpackChunkName: "component---src-templates-shopify-category-list-tsx" */),
  "component---src-templates-tech-stack-details-tsx": () => import("./../../../src/templates/tech-stack-details.tsx" /* webpackChunkName: "component---src-templates-tech-stack-details-tsx" */),
  "component---src-templates-work-details-tsx": () => import("./../../../src/templates/work-details.tsx" /* webpackChunkName: "component---src-templates-work-details-tsx" */)
}

