import { GatsbyBrowser } from "gatsby";

import "./src/styles/global.css";

// fixes - not-scrolling-to-top-on-route-change issue
// https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};


export { wrapPageElement } from "./gatsby-shared";
